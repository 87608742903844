/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.12.4-v202503220058-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqWorkbooksApiClass {
 
   constructor() {}

  /**
   * @summary Put the specified item at the top of the recently accessed list for the workbook
   * @param {string} workbookId - ID of the workbook
   * @param {string} itemId - Item to be added
   */
  public addRecentlyAccessed(
    {
      workbookId,
      itemId
    } : {
      workbookId: string,
      itemId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(workbookId))}/recently-accessed/${encodeURIComponent(String(itemId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemSearchPreviewListV1>;
  }

  /**
   * @summary Archive a workbook by id
   * @param {string} id - ID of the workbook to archive
   */
  public archiveWorkbook(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ArchiveOutputV1>;
  }

  /**
   * @summary Archive a worksheet
   * @param {string} workbookId - ID of the workbook on which the worksheet exists
   * @param {string} worksheetId - ID of the worksheet to archive
   */
  public archiveWorksheet(
    {
      workbookId,
      worksheetId
    } : {
      workbookId: string,
      worksheetId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(workbookId))}/worksheets/${encodeURIComponent(String(worksheetId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ArchiveOutputV1>;
  }

  /**
   * @summary Create a workbook
   */
  public createWorkbook(
    body: models.WorkbookInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/workbooks`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.WorkbookOutputV1>;
  }

  /**
   * @summary Create a worksheet
   * @param {string} workbookId - ID of the workbook on which to create the worksheet
   */
  public createWorksheet(
    body: models.WorksheetInputV1,
    {
      workbookId
    } : {
      workbookId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(workbookId))}/worksheets`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.WorksheetOutputV1>;
  }

  /**
   * @summary Forces the thumbnail to be regenerated. Normally new thumbnails are generated automatically as worksteps are added to the worksheet. However, thumbnail generation is normally limited to once a day, so this endpoint can be used to force a new thumbnail to be generated. When the thumbnail is complete a message will be sent over the thumbnail websocket channel.
   * @param {string} workbookId - The ID of the workbook for containing the worksheet
   * @param {string} worksheetId - The ID of the worksheet
   */
  public createWorksheetThumbnail(
    {
      workbookId,
      worksheetId
    } : {
      workbookId: string,
      worksheetId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(workbookId))}/worksheets/${encodeURIComponent(String(worksheetId))}/thumbnail`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Append a new workstep to the worksheet
   * @param {string} workbookId - ID of the workbook on which the worksheet exists
   * @param {string} worksheetId - ID of the worksheet on which to append the new workstep
   * @param {boolean} [noWorkstepMessage=false] - Option to not send a workstep message notifying clients that the current workstep of the worksheet has been updated. Should only be used when multiple worksteps are being pushed in quick succession and a subsequent call will be made to set the current workstep.
   */
  public createWorkstep(
    body: models.WorkstepInputV1,
    {
      workbookId,
      worksheetId,
      noWorkstepMessage
    } : {
      workbookId: string,
      worksheetId: string,
      noWorkstepMessage?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(workbookId))}/worksheets/${encodeURIComponent(String(worksheetId))}/worksteps`,
      data: body,

      params: omitBy({
        ['noWorkstepMessage']: noWorkstepMessage
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.WorkstepOutputV1>;
  }

  /**
   * @summary Get the recently accessed list for the workbook
   * @param {string} workbookId - ID of the workbook
   */
  public getRecentlyAccessed(
    {
      workbookId
    } : {
      workbookId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(workbookId))}/recently-accessed`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemSearchPreviewListV1>;
  }

  /**
   * @summary Get items referenced by workstep or a journal
   * @param {string} worksheetId - ID of the worksheet associated with journal that references items to return
   * @param {string} workstepId - ID of the workstep that references items to return
   */
  public getReferencedItems(
    {
      worksheetId,
      workstepId
    } : {
      worksheetId: string,
      workstepId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    if (isNil(workstepId)) {
      throw new Error("'workstepId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/workbooks/worksheets/${encodeURIComponent(String(worksheetId))}/worksteps/${encodeURIComponent(String(workstepId))}/referenced-items`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ReferencedItemsOutputV1>;
  }

  /**
   * @summary Get a workbook
   * @param {string} id - ID of the workbook to retrieve
   * @param {boolean} [fullAncestry] - If true, the actual ancestor folders of the workbook will be returned, which includes the home folder of the relevant user. Otherwise, if a workbook is shared, then only the ancestors that are shared will be returned. Requires admin privileges.
   */
  public getWorkbook(
    {
      id,
      fullAncestry
    } : {
      id: string,
      fullAncestry?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(id))}`,

      params: omitBy({
        ['fullAncestry']: fullAncestry
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.WorkbookOutputV1>;
  }

  /**
   * @summary Get a collection of workbooks
   * @param {string} [userId] - The user ID to return workbooks for, defaults to the current user if not specified. Only an admin user is allowed to specify a user ID.
   * @param {boolean} [isArchived=false] - True to filter results to only workbooks that have been archived, false to filter results to workbooks that are not archived.
   * @param {string} [sortOrder=createdAt asc] - A field by which to order the workbooks followed by a space and &#x27;asc&#x27; or &#x27;desc&#x27;. Field name can be one of: createdAt, updatedAt, name
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getWorkbooks(
    {
      userId,
      isArchived,
      sortOrder,
      offset,
      limit
    } : {
      userId?: string,
      isArchived?: boolean,
      sortOrder?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/workbooks`,

      params: omitBy({
        ['userId']: userId,
        ['isArchived']: isArchived,
        ['sortOrder']: sortOrder,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.WorkbookOutputListV1>;
  }

  /**
   * @summary Get a worksheet
   * @param {string} workbookId - ID of the workbook for which to retrieve the worksheets
   * @param {string} worksheetId - ID of the worksheet to retrieve
   */
  public getWorksheet(
    {
      workbookId,
      worksheetId
    } : {
      workbookId: string,
      worksheetId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(workbookId))}/worksheets/${encodeURIComponent(String(worksheetId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.WorksheetOutputV1>;
  }

  /**
   * @summary Get the thumbnail for this worksheet
   * @param {string} workbookId - The ID of the workbook for containing the worksheet
   * @param {string} worksheetId - The ID of the worksheet
   */
  public getWorksheetThumbnail(
    {
      workbookId,
      worksheetId
    } : {
      workbookId: string,
      worksheetId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(workbookId))}/worksheets/${encodeURIComponent(String(worksheetId))}/thumbnail`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Get a list of reports that reference a given worksheet
   * @param {string} workbookId - The ID of the workbook for containing the worksheet
   * @param {string} worksheetId - The ID of the worksheet
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getWorksheetUsages(
    {
      workbookId,
      worksheetId,
      offset,
      limit
    } : {
      workbookId: string,
      worksheetId: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(workbookId))}/worksheets/${encodeURIComponent(String(worksheetId))}/usages`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AnnotationListOutputV1>;
  }

  /**
   * @summary Get a collection of worksheets
   * @param {string} workbookId - The ID of the workbook for which to retrieve the worksheets
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {boolean} [isArchived=false] - Whether to return archived worksheets
   */
  public getWorksheets(
    {
      workbookId,
      offset,
      limit,
      isArchived
    } : {
      workbookId: string,
      offset?: number,
      limit?: number,
      isArchived?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(workbookId))}/worksheets`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit,
        ['isArchived']: isArchived
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.WorksheetOutputListV1>;
  }

  /**
   * @summary Get a workstep
   * @param {string} workbookId - ID of the workbook for which to retrieve the worksteps
   * @param {string} worksheetId - ID of the worksheet for which to retrieve the worksteps
   * @param {string} workstepId - ID of the workstep to retrieve
   */
  public getWorkstep(
    {
      workbookId,
      worksheetId,
      workstepId
    } : {
      workbookId: string,
      worksheetId: string,
      workstepId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    if (isNil(workstepId)) {
      throw new Error("'workstepId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(workbookId))}/worksheets/${encodeURIComponent(String(worksheetId))}/worksteps/${encodeURIComponent(String(workstepId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.WorkstepOutputV1>;
  }

  /**
   * @summary Get a list of worksteps starting from the given workstep, searching in the given direction.
   * @param {string} workbookId - ID of the workbook for which to retrieve the worksteps
   * @param {string} worksheetId - ID of the worksheet for which to retrieve the worksteps
   * @param {string} fromWorkstepId - ID of the workstep from where the search starts
   * @param {string} direction - The direction of the search to retrieve worksteps starting from the given one
   * @param {number} [limit=1000] - The maximum number of worksteps to be retrieved during the search.
   */
  public getWorksteps(
    {
      workbookId,
      worksheetId,
      fromWorkstepId,
      direction,
      limit
    } : {
      workbookId: string,
      worksheetId: string,
      fromWorkstepId: string,
      direction: DirectionEnum,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    if (isNil(fromWorkstepId)) {
      throw new Error("'fromWorkstepId' parameter required");
    }

    if (isNil(direction)) {
      throw new Error("'direction' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(workbookId))}/worksheets/${encodeURIComponent(String(worksheetId))}/worksteps`,

      params: omitBy({
        ['fromWorkstepId']: fromWorkstepId,
        ['direction']: direction,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.WorkstepChronologyOutputV1>;
  }

  /**
   * @summary Move a Worksheet to a new location in the same Workbook
   * @param {string} workbookId - ID of the workbook on which the worksheet exists
   * @param {string} worksheetId - ID of the worksheet on which the workstep exists
   * @param {string} [nextWorksheetId] - ID of the worksheet that the specified worksheet should precede. If not supplied then the worksheet will be moved to be the last worksheet in the workbook.
   */
  public moveWorksheet(
    {
      workbookId,
      worksheetId,
      nextWorksheetId
    } : {
      workbookId: string,
      worksheetId: string,
      nextWorksheetId?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(workbookId))}/worksheets/${encodeURIComponent(String(worksheetId))}/next`,

      params: omitBy({
        ['nextWorksheetId']: nextWorksheetId
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.WorksheetOutputV1>;
  }

  /**
   * @summary Set the current workstep
   * @param {string} workbookId - ID of the workbook on which the worksheet exists
   * @param {string} worksheetId - ID of the worksheet on which the workstep exists
   * @param {string} workstepId - ID of the workstep to set as the current one
   */
  public setCurrentWorkstep(
    {
      workbookId,
      worksheetId,
      workstepId
    } : {
      workbookId: string,
      worksheetId: string,
      workstepId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    if (isNil(workstepId)) {
      throw new Error("'workstepId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(workbookId))}/worksheets/${encodeURIComponent(String(worksheetId))}/worksteps/${encodeURIComponent(String(workstepId))}/current`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.WorkstepOutputV1>;
  }

  /**
   * @summary Set the topic's renderer.
   * @param {string} id - ID of the topic to change the renderer of
   * @param {string} userId - The user to make the topic&#x27;s renderer
   */
  public setRenderer(
    {
      id,
      userId
    } : {
      id: string,
      userId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(userId)) {
      throw new Error("'userId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/workbooks/${encodeURIComponent(String(id))}/renderer`,

      params: omitBy({
        ['userId']: userId
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

}

export enum DirectionEnum {
    Before = 'Before' as any,
    After = 'After' as any
}

export const sqWorkbooksApi = new sqWorkbooksApiClass();
